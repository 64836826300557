import { useTranslation } from "@emisgroup/application-intl";
import React from "react";
import { OrganisationSearchRecord } from "../types";
import buildAddressSummary from "../utils";

type ResultProps = { organisationSearchRecord: OrganisationSearchRecord };
function Result({ organisationSearchRecord, organisationSearchRecord: { name, address } }: ResultProps) {
    const { t } = useTranslation();
    const addressSummary = buildAddressSummary(address);
    const baseUrlDefined = Boolean(organisationSearchRecord.baseUrl);
    const disabledClassName = baseUrlDefined ? "" : "organisation-item-disabled";

    return (
        <div className="result-item nhsuk-u-margin-bottom-0 nhsuk-u-padding-top-5">
            <div className="nhsuk-grid-row results__details">
                <div className="nhsuk-grid-column-one-half nhsuk-u-margin-bottom-5">
                    <div className="nhsuk-u-padding-top-0">
                        <a
                            data-testid="organisation-list-item"
                            className={`organisation-item ${disabledClassName} nhsuk-heading-m`}
                            href={`${organisationSearchRecord.baseUrl ?? ""}`}
                        >
                            {name}
                        </a>
                    </div>
                    <p className="nhsuk-list nhsuk-u-margin-bottom-2">{addressSummary}</p>
                    {!baseUrlDefined && <span className="nhsuk-tag--red">{t("Practice.NoPatientLedServices")}</span>}
                </div>
            </div>
        </div>
    );
}

export default Result;
