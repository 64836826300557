import { OrganisationAddress } from "../api/getService";

const buildAddressSummary = (address: OrganisationAddress) =>
    address
        ? [
              address.addressLine1,
              address.addressLine2,
              address.addressLine3,
              address.addressLine4,
              address.addressCity,
              address.addressRegion,
              address.postalCode,
          ]
              .filter(Boolean)
              .join(", ")
        : "";

export default buildAddressSummary;
